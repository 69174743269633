import styled from '@emotion/styled';

import logo from '../assets/logo.svg';

export default styled.div`
  width: 80%;
  height: 80%;
  background-size: contain;
  background: url(${logo}) no-repeat;
`;
