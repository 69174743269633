import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default function InternalLink({ link, label }) {
  return (
    <AniLink cover bg="#2E3440" to={link} activeClassName="active-link">
      {label}
    </AniLink>
  );
}

InternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
